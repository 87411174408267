<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="800"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 11 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <!-- 所属运营商 -->
          <a-form-item label="调拨单号:">
            <div class="ud-text-secondary">
              {{ data.allotCode }}
            </div>
          </a-form-item>
          <!-- 厂家 -->
          <a-form-item label="调拨单状态:">
            <div class="ud-text-secondary">
              {{ data.allotState }}
            </div>
          </a-form-item>
          <!-- 型号 -->
          <a-form-item label="调出方:">
            <div class="ud-text-secondary">{{ data.out }}</div>
          </a-form-item>
          <!-- 机柜尺寸 -->
          <a-form-item label="调入方:">
            <div class="ud-text-secondary">{{ data.join }}</div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
              <a-form-item label="调出站点:">
            <div class="ud-text-secondary">{{ data.outPoint }}</div>
          </a-form-item>
          <!-- 仓位尺寸 -->
          <a-form-item label="调入站点:">
            <div class="ud-text-secondary">
              {{ data.joinPoint }}
            </div>
          </a-form-item>
          <!-- 屏幕尺寸 -->
          <a-form-item label="电池编号:">
            <div class="ud-text-secondary">
              {{ data.batteryCode }}
            </div>
          </a-form-item>
          <!-- 仓位数 -->
          <a-form-item label="创建时间:">
            <div class="ud-text-secondary">
              {{ data.createTime }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'brandDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
</style>
